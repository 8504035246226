import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../../components/layout/layout"
import SEO from "../../components/layout/seo"
import Menu from "../../components/menu/menu"
import Index from "../../components/project/index"
import Intro from "../../components/project/intro"
import IntroMobile from "../../components/project/introMobile"
import Description from "../../components/project/description"
import Show from "../../components/project/show"
import ShowMobile from "../../components/project/showMobile"
import Details from "../../components/project/details"
import Voltar from "../../components/project/voltar.js"
import Footer from "../../components/footer/footer"

const LotesMaiaPage = ({data}) => {
  
  return(
    <Layout size={useBreakpoint().mobile ? null : ["clamp(100% - 144px, 100% - 4vw, 100% - 72px)", "clamp(72px, 4vw, 144px)"]} inv={data.globalJson.invisual}>
      <SEO title="Lotes Maia"/>
      {useBreakpoint().mobile ? (
        <>
          <Menu data={data.globalJson.menu}/>
          <IntroMobile data={data.lotesMaiaJson.intro}/>
          <Description data={data.lotesMaiaJson.description}/>
          <ShowMobile data={data.lotesMaiaJson.show}/>
          {data.lotesMaiaJson.details.map((data, i)=>(
            <Details data={data} key={"details" + i}/>
          ))}
          <Voltar />
          <Footer data={data.globalJson.footer} small/>
        </>
      ) : (
        <>
          <Menu data={data.globalJson.menu}/>
          <Index data={data.lotesMaiaJson.sections} filter={3}/>
          <Intro data={data.lotesMaiaJson.intro}/>
          <Description data={data.lotesMaiaJson.description}/>
          <Show data={data.lotesMaiaJson.show}/>
          {data.lotesMaiaJson.details.map((data, i)=>(
            <Details data={data} key={"details" + i}/>
          ))}
          <Footer data={data.globalJson.footer}/>
        </>
      )}
    </Layout>
  )
}
export default LotesMaiaPage

export const Json = graphql`
  query lotesMaia {
    lotesMaiaJson{
      sections{
        section
        subsection
      }
      intro{
        title
        img{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3840) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      description{
        name
        title
        text
      }
      show{
        childImageSharp{
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            width: 3840
          )
        }
      }
      details{
        text
        iconInfo{
          area
        }
      }
    }
    globalJson{
      invisual{
        link
        linkTxt
      }
      menu{
        leftPage{
          background{
            childImageSharp {
              fluid(quality: 100, maxWidth: 3840) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          content{
            subtitle
            title
            text
            link
            linkTxt
          }
          info{
            address
            contact{
              newsletter
              email
              phone
              txt
            }
          }
          livro{
            childImageSharp{
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                width: 3840
              )
            }
          }
        }
        rightPage{
          bg{
            childImageSharp {
              fluid(quality: 100, maxWidth: 3840) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          links{
            link
            linkTxt
          }
          highlights{
            subtitle
            title
            list{
              img{
                childImageSharp{
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    width: 3840
                  )
                }
              }
              title
              subtitle
              link
            }
          }
        }
      }
      footer{
        livro{
          childImageSharp{
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              width: 3840
            )
          }
        }
        bg{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3840) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        contacts{
          email
          phone1
          txt
        }
        address
        privacyInfo{
          linkTxt
          link
        }
      }
    }
  }
`